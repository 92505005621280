import React from 'react'
import PropTypes from 'prop-types'
import PromiseLink from './PromiseLink'

import withAdminLinks from '../utils/withAdminLinks';

const FullPageHeading = ({ backgroundUrl, title, subtitle, link }) => (
  // <!-- CTA -->
  <div id="cta" className="section">
    {/* <!-- background section --> */}
    <div
      className="section-bg"
      style={{ backgroundImage: `url(${backgroundUrl})` }}
      data-stellar-background-ratio="0.5"
    />
    {/* <!-- /background section --> */}

    {/* <!-- container --> */}
    <div className="container">
      {/* <!-- row --> */}
      <div className="row">
        {/* <!-- cta content --> */}
        <div className="col-md-offset-2 col-md-8">
          <div className="cta-content text-center">
            <h1>{title}</h1>
            {subtitle && <p className="lead">{subtitle}</p>}
            {link && <PromiseLink to={link} className="primary-button" />}
          </div>
        </div>
        {/* <!-- /cta content --> */}
      </div>
      {/* <!-- /row --> */}
    </div>
    {/* <!-- /container --> */}
  </div>
  // <!-- /CTA -->
)

FullPageHeading.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  linkTo: PropTypes.string,
  className: PropTypes.string,
}

export default withAdminLinks(FullPageHeading)
