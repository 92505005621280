import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'

const BlogList = ({ blogs }) => {
  return (
    <div id="blog" className="section">
      {/* container */}
      <div className="container">
        {/* row */}
        <div className="row">
          {/* section title */}
          <div className="col-md-8 col-md-offset-2">
            <div className="section-title text-center">
              <h2 className="title">Our Blog</h2>
              {/* <p className="sub-title">
                Highlight from the articles from our blog
              </p> */}
            </div>
          </div>
          {/* /section title */}
          {blogs.map(post => {
            const linkUrl = `/blog/${post.fields.slug}`
            return (
              <div className="col-md-4" key={post.id}>
                <div className="article">
                  <div className="article-img">
                    {post.image && (
                      <Link to={linkUrl}>
                        <img src="./img/post-1.jpg" alt="" />
                      </Link>
                    )}
                  </div>
                  <div className="article-content">
                    <h3 className="article-title">
                      <Link to={linkUrl}>{post.title}</Link>
                    </h3>
                    <ul className="article-meta">
                      <li>
                        <small>{post.date}</small>
                      </li>
                    </ul>
                    <p>{post.excerpt}</p>
                  </div>
                </div>
              </div>
            )
          })}

          {/* /blog */}
        </div>
        {/* /row */}
      </div>
      {/* /container */}
    </div>
  )
}

BlogList.propTypes = {
  blogs: PropTypes.array,
}

export default BlogList
