import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'

import './Slideshow.css'
import PromiseLink from './PromiseLink'

class Slideshow extends React.Component {
  render() {
    const { items } = this.props
    return (
      <div
        className={classnames(
          'Slideshow',
          'home-owl',
          'owl-carousel',
          'owl-theme'
        )}
      >
        {items.map((item, index) => (
          <div key={index} className="column home-item">
            {/* <!-- section background --> */}
            <div
              className="section-bg"
              style={{
                backgroundImage: `url(${_.get(item, 'images[0]')})`,
              }}
            />
            {/* <!-- /section background --> */}
            <div className="home">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <div className="home-content">
                      <h1>{item.title}</h1>
                      <p className="lead">{item.subtitle}</p>
                      <PromiseLink to={item.link} className="primary-button" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
}

const styles = {
  wrapperStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}

Slideshow.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      teaserText: PropTypes.string,
    })
  ),
}

export default Slideshow
