import React from 'react'
import PropTypes from 'prop-types'

import './RightAlignedMediaFeature.css'
import PromiseLink from './PromiseLink'

import withAdminLinks from '../utils/withAdminLinks';

const RightAlignedMediaFeature = ({
  title,
  subtitle,
  content,
  linkTo,
  imageSrc,
  className,
}) => (
  <div
    className="right-aligned-media-feature section cms-content-block"
    style={{ paddingBottom: 32 }}
  >
    {/* container */}
    <div className="container">
      {/* row */}
      <div className="row">
        {/* about content */}
        <div className="col-md-5">
          <div className="section-title">
            {title && <h2 className="title">{title}</h2>}
            {subtitle && <p className="sub-title">{subtitle}</p>}
          </div>
          <div className="content">
            <div dangerouslySetInnerHTML={{ __html: content }} />
            {linkTo && <PromiseLink to={linkTo} className="primary-button" />}
          </div>
        </div>
        {/* /about content */}
        {/* about video */}
        {true && (
          <div className="col-md-offset-1 col-md-6">
            <a href="#" className="playAnchor">
              {/* <i className="play-icon playAnchor fa fa-play" /> */}
              <img src={imageSrc} />
            </a>
          </div>
        )}
        {/* /about video */}
      </div>
      {/* /row */}
    </div>
    {/* /container */}
  </div>
)

const style = {
  playIcon: {},
  playAnchor: {},
}

RightAlignedMediaFeature.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
}

export default withAdminLinks(RightAlignedMediaFeature)
