import React from 'react'
import _ from 'lodash'
import { Link, StaticQuery } from 'gatsby'

import Layout from '../components/layout'
import RightAlignedMediaFeature from '../components/RightAlignedMediaFeature'
import HomePagePrograms from '../components/HomePagePrograms'
import FullWidthHeader from '../components/FullWidthHeader'
import FullPageHeading from '../components/FullPageHeading'
import PageFooter from '../components/PageFooter'

import Slideshow from '../components/Slideshow'
import BlogList from '../components/BlogList'
import mapMarkdownRemarks from '../components/mapMarkdownRemarks'

const IndexPage = ({
  language,
  slideshow,
  programs,
  blogPosts,
  aboutGuineaPromise,
  volunteering,
  ourCommitment,
}) => {
  return (
    <Layout language={language}>
      {/* <Slideshow items={slideshow.frontmatter.entries} /> */}
      {aboutGuineaPromise && (
        <RightAlignedMediaFeature
          node={aboutGuineaPromise}
          content={aboutGuineaPromise.html}
          title={aboutGuineaPromise.title}
          subtitle={aboutGuineaPromise.subtitle}
          linkTo={aboutGuineaPromise.link}
          imageSrc={_.get(aboutGuineaPromise, 'images[0]')}
        />
      )}
      {!_.isEmpty(programs) && <HomePagePrograms node={programs} programs={programs} />}
      <FullWidthHeader
        node={ourCommitment}
        title={ourCommitment.title}
        subtitle={ourCommitment.subtitle}
        linkTo={ourCommitment.link}
      />
      {false && volunteering && (
        <FullPageHeading
          title={volunteering.title}
          subtitle={volunteering.subtitle}
          link={volunteering.link}
          backgroundUrl="https://colorlib.com/preview/theme/charity2/img/background-1.jpg"
        />
      )}
      {!_.isEmpty(blogPosts) && <BlogList blogs={blogPosts} />}
      <PageFooter language={language} />
    </Layout>
  )
}
export default ({pageContext, ...props}) => (
  <StaticQuery
    query={graphql`
      query {
        volunteering: allMarkdownRemark(
          filter: { frontmatter: { tags: { in: "#homepage-volunteering" } } }
        ) {
          edges {
            node {
              ...AllMarkdownRemarkDataFragment
            }
          }
        }
    
        slideshows: allMarkdownRemark(
          filter: { frontmatter: { tags: { in: "#homepage-slideshow" } } }
        ) {
          edges {
            node {
              ...AllMarkdownRemarkDataFragment
            }
          }
        }
    
        aboutGuineaPromise: allMarkdownRemark(
          filter: { frontmatter: { tags: { in: "#homepage-our-mission" } } }
        ) {
          edges {
            node {
              ...AllMarkdownRemarkDataFragment
            }
          }
        }
    
        ourCommitment: allMarkdownRemark(
          filter: { frontmatter: { tags: { in: "#homepage-our-commitment" } } }
        ) {
          edges {
            node {
              ...AllMarkdownRemarkDataFragment
            }
          }
        }
    
        blogPosts: allMarkdownRemark(
          filter: { frontmatter: { type: { eq: "BlogPost" } } }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          edges {
            node {
              ...AllMarkdownRemarkDataFragment
            }
          }
        }
    
        programs: allMarkdownRemark(
          filter: { frontmatter: { tags: { in: "#programs" } } }
        ) {
          edges {
            node {
              ...AllMarkdownRemarkDataFragment
            }
          }
        }
      }`}
    render={(data) => {
      const language = _.get(pageContext, 'language')
      const programs = mapMarkdownRemarks(data.programs.edges || [], language)

      const aboutGuineaPromise = _.first(
        mapMarkdownRemarks(data.aboutGuineaPromise.edges || [], language)
      )

      const ourCommitment = _.first(
        mapMarkdownRemarks(data.ourCommitment.edges || [], language)
      )
      const slideshow = data.slideshows.edges[0].node
      const volunteering = _.first(
        mapMarkdownRemarks(data.volunteering.edges || [], language)
      )

      const blogPosts = data.blogPosts.edges.map(edge => {
        return {
          ...edge.node,
          ...edge.node.fields,
          ...edge.node.frontmatter,
        }
      })

      return (
        <IndexPage
          language={language}
          slideshow={slideshow}
          aboutGuineaPromise={aboutGuineaPromise}
          volunteering={volunteering}
          blogPosts={[]}
          ourCommitment={ourCommitment}
          programs={_.first(programs)}
          data={data}
          {...props}
        />)
    }} />
)