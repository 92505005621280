import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import './PageFooter.css'
import { graphql } from 'gatsby'
import mapMarkdownRemarks from './mapMarkdownRemarks'
import PromiseLink from './PromiseLink'

import withAdminLinks from '../utils/withAdminLinks';

const ProgramTeaser = ({ item }) => (
  <div className="col-md-4">
    <div className="causes">
      {item.image && (
        <div className="causes-img">
          <PromiseLink to={item.link}>
            <img src="./img/post-1.jpg" alt />
          </PromiseLink>
        </div>
      )}
      <div className="causes-content">
        <h3>
          <PromiseLink primary={false} to={item.link}>
            {item.title}
          </PromiseLink>
        </h3>
        <div dangerouslySetInnerHTML={{ __html: item.shortExcerpt }} />
        <PromiseLink to={item.link} className="primary-button causes-donate" />
      </div>
    </div>
  </div>
)

class HomePagePrograms extends React.Component {
  render() {
    const { programs, title, subtitle } = this.props
    return (
      <div className="HomePagePrograms section">
        {/* container */}
        <div className="container">
          {/* row */}
          <div className="row">
            {/* section title */}
            <div className="col-md-8 col-md-offset-2">
              <div className="section-title text-center">
                <h2 className="title">{title}</h2>
                <p
                  className="sub-title"
                  dangerouslySetInnerHTML={{ __html: subtitle }}
                />
              </div>
            </div>
            {/* section title */}
            {programs.map(program => (
              <ProgramTeaser key={program.title} item={program} />
            ))}
          </div>
          {/* /row */}
        </div>
        {/* /container */}
      </div>
    )
  }
}

HomePagePrograms.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
}

const WrappedHomePagePrograms = withAdminLinks(HomePagePrograms);

export default ({ programs, node }) => {
  return (
    <WrappedHomePagePrograms
      node={node}
      title={programs.title}
      subtitle={programs.subtitle}
      programs={programs.entries}
    />
  )
}
